import 'typeface-roboto';

import {
  ApolloProvider,
  useQuery
} from "@apollo/react-hooks";
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  createMuiTheme
} from '@material-ui/core';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation
} from "react-router-dom";

import ApolloClient from 'apollo-boost'
import FullscreenLoading from './components/FullscreenLoading';
import {
  GET_FORM_DATA_BY_DOMAIN_NAME
} from './data/queries';
import React from 'react';
import SaleFormContainer from './components/SaleForm/SaleFormContainer';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';

// Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-5WJVTS6'
}
TagManager.initialize(tagManagerArgs)

// Defautl templete definition
const defaultConstantineTheme = createMuiTheme({
  palette: {
    primary: {  
      main: '#23a455'
    }
  }
});

// hook for use queryStrig
const useQuerystring = () => {
  return new URLSearchParams(useLocation().search);
}

// Apollo client to connect with graphql backend
const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL
});

function App() {
  const [constantineFormsTheme, setConstantineFormsTheme] = React.useState(defaultConstantineTheme);
  const [domainName,] = React.useState(window.location.hostname);
  const [querystrings, setQuerystrings] = React.useState(useQuerystring());
  const [saleFormSettings, setSaleFormSettings] = React.useState({})

  // get the basic info to create the form
  const {loading, error} = useQuery(GET_FORM_DATA_BY_DOMAIN_NAME, {
    variables: {domainName: domainName},
    onCompleted: data => {
      if (data && data.merchantFormDomainName) {
        // Setup a custom theme depending of the merchant
        setConstantineFormsTheme(createMuiTheme({
          palette: {
            primary: {  
              main: data.merchantFormDomainName.merchantSaleForm.primaryColor
            }
          }
        }));
        // save form settings from server
        setSaleFormSettings({
          domainName: domainName,
          querystrings: querystrings,
          title: data.merchantFormDomainName.merchantSaleForm.title,
          description: data.merchantFormDomainName.merchantSaleForm.description,
          fields: data.merchantFormDomainName.merchantSaleForm.formFields.edges.map(edge => { return edge.node }),
          finalMessage: data.merchantFormDomainName.merchantSaleForm.finalMessage,
          merchantLogo: `${process.env.REACT_APP_STATIC_URL}/${data.merchantFormDomainName.merchantSaleForm.logo}`,
          pagoLogo: `${process.env.REACT_APP_STATIC_URL}/pago_logo.svg`,
          allowPrefill: data.merchantFormDomainName.merchantSaleForm.allowPrefill || false,
          allowPrefillRedirect: data.merchantFormDomainName.merchantSaleForm.allowPrefillRedirect || false,
          prefilledValues: querystrings,
        });
      }
    }
  });

  // Effect hooks
  // Put querystrins as array objects.
  React.useEffect(() => {
    if (querystrings && querystrings.entries) {
      let _querystrings = {}
      for (const [key, value] of (querystrings.entries() || {})) {
        _querystrings[key] = value;
      }
      setQuerystrings(_querystrings);
    }
  }, [querystrings])

  
  if (loading) return <FullscreenLoading open={true}/>;
  if (error) return <p>Error</p>; //replace by component

  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <MuiThemeProvider theme={constantineFormsTheme}>
        <ThemeProvider theme={constantineFormsTheme}>
          <Switch>
            <Route path="/final">
              <SaleFormContainer saleFormSettings={saleFormSettings} showFinalMessage={true} />
            </Route>
            <Route path="/">
              <SaleFormContainer saleFormSettings={saleFormSettings} />
            </Route>
          </Switch>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

// AppWrapper definition with ApolloProvider and Router to be used inside App Component
function AppWrapper() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  )
}

export default AppWrapper;

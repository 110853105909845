import * as yup from "yup";

import {
  AppBar,
  Button,
  Collapse,
  Grid,
  Toolbar,
  Typography
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import Container from "@material-ui/core/Container";
import FormField from "./FormField";
import FullscreenLoading from "../FullscreenLoading";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PropTypes from "prop-types";
import React from "react";
import _ from 'lodash';
import finalHeroImage from '../../final_hero.png';
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { validateCUITFormat } from "../../utils";
import { validateRut } from '@fdograph/rut-utilities';

// Yup library configuration
yup.setLocale({
  mixed: {
    required: 'Debe completar este valor',
    notType: 'Ingresar información correcta'
  },
  string: {
    email: 'Ingrese un email válido',
    // eslint-disable-next-line
    min: 'Debe ser como mínimo ${min} de largo',
    // eslint-disable-next-line
    max: 'Debe ser como máximo ${max} de largo'
  },
  number: {
    // eslint-disable-next-line
    min: 'Debe ser mayor a ${min}',
    // eslint-disable-next-line
    max: 'Debe ser menor a ${max}'
  }
});

yup.addMethod(yup.string, 'rut', function(args = {}) {
  const message = args.message ? args.message : 'Invalid RUT Number';
  return this.test('rut', message, function(value) {
    const {createError} = this;
    return validateRut(value) || createError(message);
  });
});

yup.addMethod(yup.number, 'cuit', function(args = {}) {
  const message = args.message ? args.message : 'Invalid CUIT number';
  return this.test('cuit', message, function(value) {
    const {createError} = this;
    return validateCUITFormat(value) || createError(message);
  });
});


// Styled components
const FlexGrow = styled.div`
  flex-grow: 1;
`;

const FormToolbar = styled(Toolbar)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const FormToolbarLogo = styled.a`
  max-width: 40%;
  & img {
    height: 40px;
    display: block;
  }
`;

const Subtitle = styled(Typography)`
  ${({ theme }) => `
    margin: ${theme.spacing(3, 0, 0, 0)}
  `}
`;

const SubmitButton = styled(Button)`
  ${({ theme }) => `
    margin: ${theme.spacing(3, 0, 2)}
  `}
`;

const Form = styled("form")`
  ${({ theme }) => `
    width: 100%;
    margin-top: ${theme.spacing(3)}px;
  `}
`;

const Paper = styled.div`
  ${({ theme }) => `
    background-color: #ffffff;
    padding-top: ${theme.spacing(4)}px;
    padding-bottom: ${theme.spacing(4)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const FinalImage = styled.img`
  max-height: 200px;
`;

function SaleForm(props) {
  let {
    allowPrefill,
    allowPrefillRedirect,
    description,
    fields,
    finalMessage,
    domainName,
    merchantLogo,
    pagoLogo, 
    prefilledValues,
    title,
    dataLoading,
    dataError,
    dataSubmit,
    showFinalMessage
  } = props;

  const [allowPrefillRedirectState, setAllowPrefillRedirectState] = React.useState(allowPrefillRedirect)
  const [formValidationSchema, setFormValidationSchema] = React.useState({});
  const [internalFields, setInternalFields] = React.useState([]);
  const {register, handleSubmit, errors, getValues} = useForm({validationSchema: formValidationSchema});

  const normalizeFields = React.useCallback(() => {
    // Generate confirmation fields based on originla field and Flat
    let fieldsAndConfirmations = _.flatMap(fields, (field) => {
      // Allow prefill values
      if (allowPrefill && prefilledValues && field.internalName && prefilledValues[field.internalName]) {
        field['value'] = prefilledValues[field.internalName];
      }
      // Create confirmation fields
      if (field.askConfirmation) {
        let confirmationField = {
          ...field, 
          internalName: `conf-${field.internalName}`,
          name: `Confirmación ${field.name}`,
          compareWith: field,
          order: (field.order || 0) + .5
        }
        field = {
          ...field,
          compareWith: confirmationField
        }
        return [field, confirmationField]
      } else {
        return field
      }
    });
    // Sort field based on order attr
    let _sortedFields = fieldsAndConfirmations.sort((f1, f2) => { 
      return (f1.order || 0) - (f2.order || 0)
    });
    setInternalFields(_sortedFields);
  }, [allowPrefill, fields, prefilledValues]);

  // Setup fields validation
  const createValidationSchema = (internalFields) => {
    const fieldsValidation = {}
    internalFields.forEach((field) => {
      // Type validations
      switch(field.type) {
        case 'TEXT':
          fieldsValidation[field.internalName] = yup.string();
          if (field.minLength) {
            fieldsValidation[field.internalName] = fieldsValidation[field.internalName].min(field.minLength)
          }
          if (field.maxLength) {
            fieldsValidation[field.internalName] = fieldsValidation[field.internalName].max(field.maxLength)
          }
          break;
        case 'NUMBER':
          fieldsValidation[field.internalName] = yup.number()
          if (field.minValue) {
            fieldsValidation[field.internalName] = fieldsValidation[field.internalName].min(field.minValue)
          }
          if (field.maxValue) {
            fieldsValidation[field.internalName] = fieldsValidation[field.internalName].max(field.maxValue)
          }
          break;
        case 'RUT':
          fieldsValidation[field.internalName] = yup.string().rut({message: 'RUT no válido'});
          break;
        case 'CUIT':
          fieldsValidation[field.internalName] = yup.number().cuit({message: 'CUIT no válido'});
          break;
        case 'EMAIL':
          fieldsValidation[field.internalName] = yup.string().email()
          break;
        case 'BOOLEAN':
          fieldsValidation[field.internalName] = yup.boolean()
          break;
        default:
          fieldsValidation[field.internalName] = yup.string()
      }
      // Required validation
      if (field.required) {
        fieldsValidation[field.internalName] = fieldsValidation[field.internalName].required()
      }
      // Confirmation Fields
      if (field.compareWith) {
        fieldsValidation[field.internalName] = fieldsValidation[field.internalName]
        .oneOf([yup.ref(field.compareWith.internalName), null], 'Valores deben coincidir')
        .required()
      }
      // Set schema to yup
      setFormValidationSchema(yup.object().shape(fieldsValidation));
    });
  }

  const handleFormSubmit = React.useCallback(data => {
    setAllowPrefillRedirectState(false);
    let main_data = {
      formDomain: domainName,
      price: data.price || null
    }
    let extra_data = {}
    for (const key in data) {
      if (Object.keys(main_data).indexOf(key) < 0) {
        extra_data[key] = data[key];
      }
    }
    // we need to put this code in the container component
    dataSubmit({variables: {...main_data, extraData: JSON.stringify(extra_data)}});
    
  }, [domainName, dataSubmit]);

  React.useEffect(() => {
    if (allowPrefillRedirectState && formValidationSchema.isValid) {
      let data = getValues();
      formValidationSchema.isValid(data).then(valid => {
        if (valid) {
          handleFormSubmit(data);
        }
      });
    }
  },[allowPrefillRedirectState, formValidationSchema, handleFormSubmit, getValues]);
  
  // Effects hook
  React.useEffect(() => {
    normalizeFields();
  }, [fields, normalizeFields]);

  React.useEffect(() => {
    createValidationSchema(internalFields);
  }, [internalFields]);

  
  return (
    <React.Fragment>
      <FullscreenLoading open={dataLoading} ></FullscreenLoading>
      <AppBar position="static">
        <FormToolbar href="#">
          { merchantLogo ?
            <FormToolbarLogo>
              <img alt="merchant-logo" src={merchantLogo} />
            </FormToolbarLogo>
            : null
          }
          <FlexGrow />
          { pagoLogo ?
            <FormToolbarLogo>
              <img alt="pago-logo" src={pagoLogo} />
            </FormToolbarLogo>
            : null
          }
        </FormToolbar>
      </AppBar>
      <Container component="main" maxWidth="xs" style={{backgroundColor: '#ffffff'}}>
        { showFinalMessage ?
          <Paper>
            <FinalImage src={finalHeroImage} alt='Hero Kid PAGO46'/>
            <Typography variant='h6' align='center' gutterBottom style={{marginTop: '20px'}}>
              {finalMessage && finalMessage !== '' ? finalMessage : 'Gracias por elegirnos.  Tu pago ha finalizado'}
            </Typography>    
          </Paper>
          :
            <Paper>
              <Collapse in={dataError}>
                <Alert severity="error">Ha ocurrido un error. Intente nuevamente</Alert>
              </Collapse>
              <Typography component='h1' variant='h5'>{title}</Typography>
              { description ? <Subtitle variant='subtitle1'>{description}</Subtitle> : null }
              <Form onSubmit={handleSubmit(handleFormSubmit)} >
                <Grid container spacing={2}>
                  {
                    internalFields.map((field, i) => {
                      return <FormField
                        errors={errors}
                        inputRef={register}
                        key={i} 
                        settings={field}
                      />
                    })
                  }
                </Grid>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={dataLoading}
                  role="submit"
                >
                  Continue 
                  <NavigateNextIcon />
                </SubmitButton>
              </Form>
            </Paper>
        }
      </Container>
    </React.Fragment>
  )
}

SaleForm.propTypes = {
  allowPrefillRedirect: PropTypes.bool,
  allowPrefill: PropTypes.bool.isRequired,
  description: PropTypes.string,
  domainName: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  finalMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  merchantLogo: PropTypes.string,
  pagoLogo: PropTypes.string,
  prefilledValues: PropTypes.object,
  dataLoading: PropTypes.bool,
  dataError: PropTypes.object,
  dataSubmit: PropTypes.func.isRequired,
  showFinalMessage: PropTypes.bool,
}

export default SaleForm;
import { Grid, TextField } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";

function FormField(props) {
  let {
    description,
    internalName,
    name,
    placeholder,
    value
  } = props.settings;
  
  let  {
    errors,
    onChange,
    inputRef,
  } = props;

  return (
    <Grid item xs={12} >
      <TextField
        defaultValue={value}
        error={errors[internalName] ? true : false}
        fullWidth
        helperText={errors[internalName] ? errors[internalName].message : description ? description : null}
        id={`${internalName}-textfield`}
        inputRef={inputRef}
        label={name}
        name={internalName}
        onChange={onChange}
        placeholder={placeholder}
        role={`formfield-input-${internalName}`}
        // value={value}
        variant="outlined"
      />
    </Grid>
  )
}

FormField.defaultProps = {
  errors: {}
}

FormField.propTypes = {
  errors: PropTypes.object,
  settings: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  value: PropTypes.string
}

export default FormField;
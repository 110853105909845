import { gql } from 'apollo-boost';

export const GET_FORM_DATA_BY_DOMAIN_NAME = gql`
  query MerchantSaleForm($domainName: String!) {
    merchantFormDomainName(domainName: $domainName) {
      merchantSaleForm {
        allowPrefill
        allowPrefillRedirect
        description
        finalMessage
        name
        primaryColor
        secondaryColor
        title
        logo
        formFields {
          edges {
            node {
              askConfirmation
              description
              id
              internalName
              name
              maxLength
              maxValue
              minLength
              minValue
              order
              placeholder
              required
              type
              options {
                edges {
                  node {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder(
    $formDomain: String!, 
    $price: Float!,
    $extraData: JSONString
  ){
    createOrder(
      formDomain: $formDomain
      price: $price
      extraData: $extraData
    ) {
      order {
        status
        redirectUrl
      }
    }
  }
`;
import { CREATE_ORDER } from '../../data/queries';
import React from "react";
import SaleForm from "./SaleForm";
import { useMutation } from "@apollo/react-hooks";

function SaleFormContainer(props) {
  const {saleFormSettings, showFinalMessage} = props;
  const [createOrder, {loading: dataLoading, error: dataError}] = useMutation(CREATE_ORDER);
  const [loading, setLoading] = React.useState(false);
  
  const dataSubmit = (formData) => {
    setLoading(true);
    createOrder(formData).then(({data}) => {
      window.location = data.createOrder.order.redirectUrl;
    }).catch(err => {
      console.error(err)
    });
  }
  
  React.useEffect(() => {
    if (dataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [dataLoading])
   
  return (
    saleFormSettings.domainName ?
      <SaleForm
        title={saleFormSettings.title}
        description={saleFormSettings.description}
        domainName={saleFormSettings.domainName}
        fields={saleFormSettings.fields || []}
        finalMessage={saleFormSettings.finalMessage}
        merchantLogo={saleFormSettings.merchantLogo}
        pagoLogo={saleFormSettings.pagoLogo}
        allowPrefill={saleFormSettings.allowPrefill || false}
        allowPrefillRedirect={saleFormSettings.allowPrefillRedirect || false}
        prefilledValues={saleFormSettings.querystrings}
        dataLoading={loading}
        dataError={dataError}
        dataSubmit={dataSubmit}
        showFinalMessage={showFinalMessage}
      />
    : null
  )
}

export default SaleFormContainer;

const validateCUITFormat = (cuit = 0) => {
  cuit = cuit.toString();
  let sum = 0;
  let digits = cuit.split('');
  let verifyDigit = parseInt(digits.pop());
  
  for (var i = 0; i < digits.length; i++) {
    sum += parseInt(digits[9 - i]) * (2 + (i % 6));
  }
  let generatedVerifyDigit = (11 - (sum % 11)) === 11 ? 0 : (11 - (sum % 11));
  return verifyDigit === generatedVerifyDigit;
}

module.exports.validateCUITFormat = validateCUITFormat;